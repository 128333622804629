/*
| Developed by Starton
| Filename : InteractiveCard.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { Card, CardProps, styled } from "@mui/material";
import { LinkProps } from "next/link";
import React from "react";

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type InteractiveCardProps = CardProps & {
  href?: LinkProps["href"];
};

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/

const CardStyled = styled(Card)<InteractiveCardProps>(({ theme }) => ({
  textDecoration: "none",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: theme.transitions.create(["border"]),

  "&:hover": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export function InteractiveCard({ children, ...props }: InteractiveCardProps) {
  return <CardStyled {...props}>{children}</CardStyled>;
}
