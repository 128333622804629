import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/apps/frontend/webapp/src/components/providers/AppProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/webapp/src/modules/wagmi/providers/WagmiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c0e824-20241028__react@19.0.0-rc-02c0e824-20241028/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Space_Mono\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-mono\"}],\"variableName\":\"spaceMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.3_react-dom@19.0.0-rc-02c0e824-20241028_react@19.0.0-rc-02c0e824-20241028__react@19.0.0-rc-02c0e824-20241028/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Fira_Code\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-fira-code\"}],\"variableName\":\"firaCode\"}");
;
import(/* webpackMode: "eager", webpackExports: ["WagmiContext","WagmiProvider"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccount"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccountEffect"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useAccountEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBalance"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useBalance.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlock"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useBlock.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockNumber"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockTransactionCount"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useBlockTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBytecode"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useBytecode.js");
;
import(/* webpackMode: "eager", webpackExports: ["useCall"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useCall.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChainId"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useChainId.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChains"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useChains.js");
;
import(/* webpackMode: "eager", webpackExports: ["useClient"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConfig"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useConfig.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnect"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useConnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnections"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useConnections.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectorClient"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useConnectorClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectors"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useConnectors.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDeployContract"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useDeployContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDisconnect"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useDisconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAddress"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEnsAddress.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAvatar"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEnsAvatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsName"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEnsName.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsResolver"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEnsResolver.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsText"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEnsText.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateFeesPerGas"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEstimateFeesPerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateGas"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEstimateGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateMaxPriorityFeePerGas"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useEstimateMaxPriorityFeePerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useFeeHistory"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useFeeHistory.js");
;
import(/* webpackMode: "eager", webpackExports: ["useGasPrice"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useGasPrice.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteReadContracts"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useInfiniteReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePrepareTransactionRequest"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/usePrepareTransactionRequest.js");
;
import(/* webpackMode: "eager", webpackExports: ["useProof"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useProof.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePublicClient"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/usePublicClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContract"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useReadContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContracts"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReconnect"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useReconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSendTransaction"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSendTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignMessage"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSignMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignTypedData"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSignTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSimulateContract"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSimulateContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useStorageAt"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useStorageAt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchAccount"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSwitchAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchChain"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useSwitchChain.js");
;
import(/* webpackMode: "eager", webpackExports: ["useToken"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useToken.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransaction"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionConfirmations"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useTransactionConfirmations.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionCount"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionReceipt"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyMessage"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useVerifyMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyTypedData"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useVerifyTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWaitForTransactionReceipt"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWaitForTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWalletClient"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWalletClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchAsset"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWatchAsset.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlockNumber"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWatchBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlocks"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWatchBlocks.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchContractEvent"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWatchContractEvent.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchPendingTransactions"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWatchPendingTransactions.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWriteContract"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hooks/useWriteContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate"] */ "/app/node_modules/.pnpm/wagmi@2.13.5_@tanstack+query-core@5.62.7_@tanstack+react-query@5.62.7_react@19.0.0-rc-02c0e82_cikkxecg25b6xoo5lyhyv5zqbm/node_modules/wagmi/dist/esm/hydrate.js");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/app/packages/frontend/design-system/dist/index.js");
