/*
| Developed by Starton
| Filename : user.dtos.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

/**
 * User status enumeration
 * - Active : User can connect.
 * - Locked : Administrators asked locked this account for verification.
 * - Banned : User can't connect because account is banned.
 */
export enum UserStatus {
  ACTIVE = "ACTIVE",
  LOCKED = "LOCKED",
  BANNED = "BANNED",
}

/**
 * User role
 * - Admin : Admin privileges with moderation mode
 * - User : Default role
 */
export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

/*
|--------------------------------------------------------------------------
| Mappings
|--------------------------------------------------------------------------
*/
export const UserRoleMapping = {
  [UserRole.ADMIN]: {
    label: "Administrator",
    color: "secondary",
  },
  [UserRole.USER]: {
    label: "User",
    color: "primary",
  },
};

/*
|--------------------------------------------------------------------------
| DTOs
|--------------------------------------------------------------------------
*/
export interface User {
  // Fields
  id: string;
  name: string;
  email: string;
  status: UserStatus;
  role: UserRole;
  emailVerified: boolean;
  emailVerifiedAt: string | null;
  publicMetadata: Record<string, unknown>;
  preferredLanguage: string;
  // Computed
  formattedId: string;
  connections: Array<string>;
  isEmailVerified: boolean;
  isAdmin: boolean;
  isUser: boolean;
  isActive: boolean;
  isLocked: boolean;
  isBanned: boolean;
  memberDuration: string | undefined;
}
