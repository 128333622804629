/*
| Developed by Starton
| Filename : WagmiProvider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type ReactNode, useState } from "react";
import { type State, WagmiProvider as WagmiProviderBase } from "wagmi";
import { Network } from "@starton/types";
import { getConfig } from "../config/wagmi.config";

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
  children: ReactNode;
  initialState: State | undefined;
  networks: Array<Network>;
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export function WagmiProvider({ children, initialState, networks }: Props) {
  const [config] = useState(() => getConfig(networks));
  const [queryClient] = useState(() => new QueryClient());

  return (
    <WagmiProviderBase config={config} initialState={initialState}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProviderBase>
  );
}
