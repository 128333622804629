/*
| Developed by Starton
| Filename : wagmi.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { Network } from "@starton/types";
import {
  createConfig,
  http,
  cookieStorage,
  createStorage,
  Config,
} from "wagmi";
import { injected } from "wagmi/connectors";
import { Chain, mainnet, sepolia } from "wagmi/chains";

/**
 * Get wagmi config
 * @param networks - Networks
 * @returns Wagmi config
 */
export function getConfig(networks: Array<Network>): Config {
  if (!networks || !networks.length) {
    return createConfig({
      chains: [mainnet, sepolia],
      connectors: [
        injected({
          target: "metaMask",
        }),
      ],
      ssr: true,
      storage: createStorage({
        storage: cookieStorage,
      }),
      transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(),
      },
    });
  }

  const chains: Array<Chain> = networks
    .filter((network: Network) => network.browserExtensionService)
    .map((network: Network) => ({
      id: network.chainId,
      name: network.displayName,
      testnet: network.testnet,
      blockExplorers: {
        default: {
          name: "Default",
          url: network.explorerUrl,
        },
      },
      nativeCurrency: {
        decimals: network.decimal,
        name: network.symbol,
        symbol: network.symbol,
      },
      rpcUrls: {
        default: {
          http: [
            network?.browserExtensionSettings?.rpcUrl ??
              `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
          ],
          webSocket: [
            network?.browserExtensionSettings?.rpcUrl ??
              `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
          ],
        },
        public: {
          http: [
            network?.browserExtensionSettings?.rpcUrl ??
              `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
          ],
          webSocket: [
            network?.browserExtensionSettings?.rpcUrl ??
              `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
          ],
        },
      },
      custom: {
        network: network.name,
      },
    }));

  return createConfig({
    chains: [mainnet, ...chains],
    connectors: [
      injected({
        target: "metaMask",
      }),
    ],
    ssr: true,
    storage: createStorage({
      storage: cookieStorage,
    }),
    transports: {
      [mainnet.id]: http(),
      ...chains.reduce((acc, chain) => {
        // @ts-ignore
        acc[chain.id] = http();
        return acc;
      }, {}),
    },
  });
}
