/*
| Developed by Starton
| Filename : ThemeProvider.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

"use client";

import React from "react";
import { NuqsAdapter } from "nuqs/adapters/next/app";
import { ThemeProvider } from "@starton/design-system";
import { Toaster } from "sonner";
import { PureAbility } from "@casl/ability";
import { SWRConfig } from "swr";
import { AuthProvider } from "@/modules/auth/provider";
import { AppAbility, defineRulesFor } from "@/services/casl/casl-ability";
import { useSessionMe } from "@/modules/auth/api/session.hooks";
import { AbilityProvider } from "@/services/casl/casl-context";
import { Loading } from "@/components/common";

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: user, isLoading } = useSessionMe({ refreshInterval: 0 });
  const [ability, setAbility] = React.useState<AppAbility>(new PureAbility());
  const [hasLoaded, setHasLoaded] = React.useState(false);

  // Update ability with user
  // ----------------------------------------------------------------------------
  React.useEffect(() => {
    const rules = defineRulesFor(user);
    setAbility(rules);
    if (!hasLoaded && user) {
      setHasLoaded(true);
    }
  }, [user, hasLoaded]);

  // Render
  // ----------------------------------------------------------------------------
  return (
    <SWRConfig>
      <NuqsAdapter>
        <ThemeProvider>
          <AuthProvider>
            <AbilityProvider ability={ability}>
              <Toaster position="bottom-center" />
              <Loading isLoading={isLoading ? !hasLoaded : false} />
              {children}
            </AbilityProvider>
          </AuthProvider>
        </ThemeProvider>
      </NuqsAdapter>
    </SWRConfig>
  );
};
